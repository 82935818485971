<template>
    <v-container>
        <v-row>
            <v-col cols="12" sm="8" md="8" class="text-center mx-auto  container-success">
                <h2>Danki pa bo peticion</h2>
                 <v-icon  large color="success" class="icon-message" >mdi-check-circle</v-icon> <br>
                 <p>
                     Danki pa bo peticion. Bo number di peticion ta  <b>{{$route.params.requestNumber}} </b>. 
                     ,Departamento di Asunto Social (DAS) su 
                     seccion di Bijstand lo tuma contacto telefonicamente cu bo persona,
                     porfabor keda pendiente di esaki <br>
                     <b>DAS</b> 
 
                 </p>
            </v-col>
            <v-col cols="5" class="mx-auto mt-10" >
                <router-link :to="{ name: 'AppContentChangesMenu' }" class="button" title="Bay bek">Bay bek </router-link>
            </v-col>
            
            <!-- @click="$router.push('/VerzoekerDetail/'+item.id)" -->
 
        </v-row>
    </v-container>
 </template>
 
 <script>
 export default {
 
 }
 </script>
 
 <style lang="scss">
     .container-success{
         margin-top: 80px;
         padding: 25px;
         border-radius: 8px;
         box-shadow: 3px -2px 40px 0px #d3d3d3;
         p{
             font-size: 18px;
         }
     }
 </style>